module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "conversations-embed": "static-1.21308",
      "conversations-internal-schema": "static-1.10131",
      "head-dlb": "static-1.2378",
      "hs-test-utils": "static-1.5475",
      "hubspot-url-utils": "static-1.1977",
      "HubStyleTokens": "static-2.9029",
      "I18n": "static-7.1303",
      "jasmine-runner": "static-1.3852",
      "quartz": "static-1.4039",
      "react": "static-7.133",
      "sinon": "static-1.9",
      "webpack-env": "static-1.41",
      "isMobile": "static-1.7",
      "StyleGuideUI": "static-3.433",
      "enviro": "static-4.271",
      "hs-promise-rejection-tracking": "static-1.2751",
      "PortalIdParser": "static-2.250",
      "raven": "static-3.3055",
      "raven-hubspot": "static-1.3362",
      "bend-plugin-trellis-migration": "static-1.1048",
      "moment": "static-3.26",
      "immutable": "static-2.19",
      "transmute": "static-2.29",
      "HeadJS": "static-2.518",
      "react-dom": "static-7.85",
      "foundations-components": "static-1.4170",
      "framer-motion": "static-1.30",
      "hs-test-utils-bend-plugin": "static-1.586",
      "msw": "static-1.39",
      "react-redux": "static-7.16",
      "redux": "static-4.16",
      "testing-library": "static-1.113",
      "i18n-data": "static-1.188",
      "metrics-js": "static-1.7074",
      "moment-timezone": "static-5.17",
      "react-utils": "static-2.3164",
      "jasmine": "static-4.1775",
      "quartz-core-utils": "static-1.906",
      "csstype": "static-1.8",
      "bend-plugin-foundations-components": "static-1.438",
      "floating-ui": "static-1.20",
      "foundations-assets": "static-1.2269",
      "foundations-theming": "static-1.1384",
      "hs-story-utils": "static-1.6466",
      "react-aria": "static-1.32",
      "react-select-plus": "static-1.65",
      "react-virtualized": "static-2.67",
      "styled-components": "static-2.40",
      "tanstack-table": "static-1.10",
      "ui-fonts": "static-1.327",
      "ui-images": "static-2.765",
      "hoist-non-react-statics": "static-3.9",
      "foundations-theming-base": "static-1.369",
      "react-input-autosize": "static-2.17",
      "classnames": "static-2.10"
    },
    "depPathPrefixes": {
      "conversations-embed": "/conversations-embed/static-1.21308",
      "conversations-internal-schema": "/conversations-internal-schema/static-1.10131",
      "head-dlb": "/head-dlb/static-1.2378",
      "hs-test-utils": "/hs-test-utils/static-1.5475",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1977",
      "HubStyleTokens": "/HubStyleTokens/static-2.9029",
      "I18n": "/I18n/static-7.1303",
      "jasmine-runner": "/jasmine-runner/static-1.3852",
      "quartz": "/quartz/static-1.4039",
      "react": "/react/static-7.133",
      "sinon": "/sinon/static-1.9",
      "webpack-env": "/webpack-env/static-1.41",
      "isMobile": "/isMobile/static-1.7",
      "StyleGuideUI": "/StyleGuideUI/static-3.433",
      "enviro": "/enviro/static-4.271",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.2751",
      "PortalIdParser": "/PortalIdParser/static-2.250",
      "raven": "/raven/static-3.3055",
      "raven-hubspot": "/raven-hubspot/static-1.3362",
      "bend-plugin-trellis-migration": "/bend-plugin-trellis-migration/static-1.1048",
      "moment": "/moment/static-3.26",
      "immutable": "/immutable/static-2.19",
      "transmute": "/transmute/static-2.29",
      "HeadJS": "/HeadJS/static-2.518",
      "react-dom": "/react-dom/static-7.85",
      "foundations-components": "/foundations-components/static-1.4170",
      "framer-motion": "/framer-motion/static-1.30",
      "hs-test-utils-bend-plugin": "/hs-test-utils-bend-plugin/static-1.586",
      "msw": "/msw/static-1.39",
      "react-redux": "/react-redux/static-7.16",
      "redux": "/redux/static-4.16",
      "testing-library": "/testing-library/static-1.113",
      "i18n-data": "/i18n-data/static-1.188",
      "metrics-js": "/metrics-js/static-1.7074",
      "moment-timezone": "/moment-timezone/static-5.17",
      "react-utils": "/react-utils/static-2.3164",
      "jasmine": "/jasmine/static-4.1775",
      "quartz-core-utils": "/quartz-core-utils/static-1.906",
      "csstype": "/csstype/static-1.8",
      "bend-plugin-foundations-components": "/bend-plugin-foundations-components/static-1.438",
      "floating-ui": "/floating-ui/static-1.20",
      "foundations-assets": "/foundations-assets/static-1.2269",
      "foundations-theming": "/foundations-theming/static-1.1384",
      "hs-story-utils": "/hs-story-utils/static-1.6466",
      "react-aria": "/react-aria/static-1.32",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-virtualized": "/react-virtualized/static-2.67",
      "styled-components": "/styled-components/static-2.40",
      "tanstack-table": "/tanstack-table/static-1.10",
      "ui-fonts": "/ui-fonts/static-1.327",
      "ui-images": "/ui-images/static-2.765",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "foundations-theming-base": "/foundations-theming-base/static-1.369",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "classnames": "/classnames/static-2.10"
    },
    "project": "conversations-embed",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};