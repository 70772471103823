"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInSDK = exports.hasMinimumMobileSdkVersion = void 0;
const isInSDK = () => /Hub[sS]pot ?Mobile ?SDK/.test(window.navigator.userAgent);
exports.isInSDK = isInSDK;
const hasMinimumMobileSdkVersion = ({
  minMajor,
  minMinor,
  minPatch
}) => {
  const userAgent = window.navigator.userAgent;
  const match = userAgent.match(/Hub[sS]pot ?Mobile ?SDK\/(\d+\.\d+\.\d+)/);
  if (!match) {
    return false;
  }
  const [, version] = match;
  const [sdkMajor, sdkMinor, sdkPatch] = version.split('.').map(Number);
  return sdkMajor > minMajor || sdkMajor === minMajor && sdkMinor > minMinor || sdkMajor === minMajor && sdkMinor === minMinor && sdkPatch >= minPatch;
};
exports.hasMinimumMobileSdkVersion = hasMinimumMobileSdkVersion;